
import { defineComponent, PropType } from 'vue'
import { Course } from 'momai'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    courseList: {
      type: Array as PropType<Course[]>,
      default: () => []
    },
    isHomework: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },

  setup: (props) => {
    const route = useRoute()
    const { courseSeriesId } = route.params
    const detailPath = (c: Course) => {
      if (props.isAdmin) {
        return `/admin/course-series/course/detail/${c.id}`
      }
      if (props.isHomework) {
        return `/course-series/course/homework/${c.id}`
      } else {
        return `/course-series/course-detail/${c.id}`
      }
    }
    return {
      addPath: `/admin/course-series/course/add/${courseSeriesId}`,
      detailPath
    }
  }
})
